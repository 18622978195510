<template>
    <div>
        <swiper :options="swiperOption"
                class="no-indentation mainSwiper">
            <div class="parallax-bg"
                 slot="parallax-bg"
                 data-swiper-parallax="-23%"
                 ></div>
            <swiper-slide class="no-indentation">
                <v-container fluid class="swiperSlide">
                    <v-row>
                        <v-col cols="12" lg="5" md="6" sm="12" offset-lg="6" offset-md="5" offset-sm="0" style="padding: 0">
                            <div data-swiper-parallax="-400" class="swiperBackground"></div>
                            <div class="mx-auto swiperInfo">
                                <div data-swiper-parallax="-100" class="swiperHeader">{{ $t('message.sliderHeader1', 'ru') }}</div>
                                <div data-swiper-parallax="-200" class="swiperText">
                                  <p>{{ $t('message.sliderText1', 'ru') }}</p>
                                </div>
                                <div data-swiper-parallax="-300" class="swiperBtn">
                                    <v-btn color="white" depressed @click="pushAboutCompany()">{{ $t('message.sliderButton1', 'ru') }}</v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </swiper-slide>
            <swiper-slide class="no-indentation">
                <v-container fluid class="swiperSlide">
                    <v-row>
                        <v-col cols="12" lg="5" md="6" sm="12" offset-lg="6" offset-md="5" offset-sm="0" style="padding: 0">
                            <div data-swiper-parallax="-400" class="swiperBackground"></div>
                            <div class="mx-auto swiperInfo">
                                <div data-swiper-parallax="-100" class="swiperHeader">{{ $t('message.sliderHeader2', 'ru') }}</div>
                                <div data-swiper-parallax="-200" class="swiperText">
                                    <p>{{ $t('message.sliderText2', 'ru') }}</p>
                                </div>
                                <div data-swiper-parallax="-300" class="swiperBtn">
                                    <v-btn color="white" depressed @click="pushTypesOfActivities()">{{ $t('message.sliderButton2', 'ru') }}</v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </swiper-slide>
            <swiper-slide class="no-indentation">
                <v-container fluid class="swiperSlide">
                    <v-row>
                        <v-col cols="12" lg="5" md="6" sm="12" offset-lg="6" offset-md="5" offset-sm="0" style="padding: 0">
                            <div data-swiper-parallax="-400" class="swiperBackground"></div>
                            <div class="mx-auto swiperInfo">
                                <div data-swiper-parallax="-100" class="swiperHeader">{{ $t('message.sliderHeader3', 'ru') }}</div>
                                <div data-swiper-parallax="-200" class="swiperText">
                                    <p>{{ $t('message.sliderText3', 'ru') }}</p>
                                </div>
                                <div data-swiper-parallax="-300" class="swiperBtn">
                                    <v-btn color="white" depressed @click="pushPartnership()">{{ $t('message.sliderButton3', 'ru') }}</v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
          </swiper>
    </div>
</template>

<script>
import '../../plugins/i18n'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      swiperOption: {
        speed: 1200,
        loop: true,
        autoplay: {
          delay: 7000,
          disableOnInteraction: false
        },
        parallax: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    lang () { return this.$i18n.locale }
  },
  watch: {
    lang1 () { return this.$i18n.locale }
  },
  methods: {
    pushAboutCompany () {
      this.$router.push('about_company')
    },
    pushTypesOfActivities () {
      this.$router.push('/types_of_activities')
    },
    pushPartnership () {
      this.$router.push('/for_suppliers')
    }
  }
}
</script>

<style lang="scss" scoped>
  .swiper-slide {
    font-size: 18px;
    color:#000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
    background-color: transparent!important;
    justify-content: space-around!important;
  }
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-image:  url(../../../public/img/slider/background.jpg);
    opacity: 0.3;
  }
  .swiper-slide .title {
    font-size: 41px;
    font-weight: 300;
  }
  .swiper-slide .subtitle {
    font-size: 21px;
  }
  .swiper-slide .text {
    font-size: 14px;
    max-width: 400px;
    line-height: 1.3;
  }
</style>
