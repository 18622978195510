<template>
    <div style="width: 100%!important;" class="mx-5">
        <v-btn-toggle v-model="toggle_none"
                      class="menuBtnToggle">
            <div class="subMenuBlock">
                <Submenu :submenu="$t('message.partnershipSubmenu1')" :push="pushEcologySecurity" :svg-path="svgPath1"></Submenu>
            </div>

            <div class="subMenuBlock">
                <Submenu :submenu="$t('message.partnershipSubmenu3')" :push="pushOurPartners" :svg-path="svgPath3"></Submenu>
            </div>
            <div class="subMenuBlock">
                <Submenu :submenu="$t('message.partnershipSubmenu4')" :push="pushEffectivenessProject" :svg-path="svgPath4"></Submenu>
            </div>
        </v-btn-toggle>
    </div>
</template>
<script>
import Submenu from '../ui/Submenu'
import { mdiTruckFast, mdiCarInfo, mdiAccountTieOutline, mdiAccountHeartOutline } from '@mdi/js'
export default {
  components: { Submenu },
  props: ['toggle_none'],
  name: 'PartnershipSubmenu',
  data () {
    return {
      svgPath1: mdiTruckFast,
      svgPath2: mdiCarInfo,
      svgPath3: mdiAccountTieOutline,
      svgPath4: mdiAccountHeartOutline
    }
  },
  methods: {
    pushEcologySecurity () {
      this.$router.push('/ecology_security')
    },
    pushForSuppliers () {
      this.$router.push('/for_suppliers')
    },
    pushOurPartners () {
      this.$router.push('/our_partners')
    },
    pushEffectivenessProject () {
      this.$router.push('/effect')
    }
  }
}
</script>
