<template>
    <div class="contentText">
        <v-form ref="form"
                v-model="valid"
                lazy-validation
        >
            <v-row>
                <v-col cols="12" lg="6" md="12" sm="12">
                    <v-text-field v-model="name"
                                  :counter="50"
                                  :rules="nameRules"
                                  :label="$t('message.partnershipForSuppliersForm2')"
                                  prepend-inner-icon="account_balance"
                                  class="inputStyle"
                                  required
                                  outlined
                    />
                </v-col>
                <v-col cols="12" lg="6" md="12" sm="6">
                    <v-text-field v-model="contact_name"
                                  :label="$t('message.partnershipForSuppliersForm5')"
                                  class="inputStyle"
                                  prepend-inner-icon="perm_identity"
                                  outlined
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" lg="6" md="12" sm="6">
                    <v-text-field v-model="email"
                                  :rules="emailRules"
                                  label="E-mail"
                                  prepend-inner-icon="email"
                                  class="inputStyle"
                                  required
                                  outlined
                    />
                </v-col>
                <v-col cols="12" lg="6" md="12" sm="6">
                    <VuePhoneNumberInput v-model="phone"
                                         v-on:update="updatePhone"
                                         v-bind:translations="myTranslation"
                                         required
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="12">
                    <v-textarea class="inputStyle mt-2"
                                :label="$t('message.partnershipForSuppliersForm11')"
                                outlined
                                rows="6"
                                v-model="text"
                                prepend-inner-icon="comment"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" lg="6" md="7" sm="6">
                    <v-btn outlined
                           v-on:click="send()"
                           x-large
                           color="blue darken-2"
                    >{{ $t('message.partnershipForSuppliersForm12') }}</v-btn>
                    <v-dialog v-model="dialog"
                              width="300"
                    >
                        <v-card
                        >
                            <v-card-text class="pt-6 pb-8 circle-check">
                                <i class="material-icons mr-5 mt-3 ml-2">check_circle</i>
                                <p>{{ $t('message.partnershipForSuppliersForm13') }}</p>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialog_error"
                              width="300"
                    >
                        <v-card
                        >
                            <v-card-text class="pt-6 pb-8 circle-check">
                                <i class="cansel-icon material-icons mr-5 mt-3 ml-2">cancel</i>
                                <p>{{ $t('message.partnershipForSuppliersForm14') }}</p>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
export default {
  components: { VuePhoneNumberInput },
  methods: { updatePhone: function (value) { console.log(value) },
    send: function () {
      if (this.validateForm) {
        this.$store.dispatch('mail/SEND_REPORTFORM', {
          'name': this.name,
          'contact_name': this.contact_name,
          'email': this.email,
          'phone': this.phone,
          'text': this.text

        })
      } else {
        this.$store.dispatch('mail/SHOW_REPORTFORMERROR')
      }
    }
  },
  computed: {
    validateForm: function () {
      if (!this.name || !(this.name.length < 50)) { return false }
      if (!this.email || !/.+@.+\..+/.test(this.email)) { return false }
      return true
    },
    myTranslation () {
      return {
        countrySelectorLabel: this.$t('message.partnershipForSuppliersForm6'),
        countrySelectorError: this.$t('message.partnershipForSuppliersForm6Error'),
        phoneNumberLabel: this.$t('message.partnershipForSuppliersForm7'),
        example: this.$t('message.partnershipForSuppliersForm7Example')
      }
    },
    dialog: {
      get () { return this.$store.state['mail']['reportformLoad'] },
      set (value) {
        this.name = ''
        this.contact_name = ''
        this.email = ''
        this.phone = ''
        this.text = ''
        this.$store.dispatch('mail/HIDE_FORM', 'reportformLoad')
      }
    },
    dialog_error: {
      get () { return this.$store.state['mail']['reportformError'] },
      set (value) { this.$store.dispatch('mail/HIDE_FORM', 'reportformError') }
    }
  },
  name: 'ReportForm',
  data () {
    return {
      valid: true,
      name: '',
      contact_name: '',
      email: '',
      text: '',
      nameRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length <= 50) || 'Имя должно содержать не более 50 символов'
      ],
      emailRules: [
        v => !!v || 'Обязательное поле',
        v => /.+@.+\..+/.test(v) || 'Электронная почта должна быть действительной'
      ],
      phone: '',
      phoneRules: [
        v => !!v || 'Обязательное поле',
        v => /.+@.+\..+/.test(v) || 'Телефон должен быть действительным'
      ] }
  }
}
</script>

<style scoped>

</style>
