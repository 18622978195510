<template>
  <v-col cols="12" class="mt-2 newsImg">
      <v-img :src="pathImg" contain/>
  </v-col>
</template>
<script>
import '../../plugins/i18n.js'
export default {
  props: ['pathImg'],
  data () {
    return {
      locale: 'ru'
    }
  }
}
</script>
