<template>
   <v-card class="mx-auto borderCard text-center"
           width="100%"
           height="100%"
           outlined
   >
       <v-icon class="mt-lg-9 mt-md-5 mt-sm-0">{{ svgPath }}</v-icon>
       <v-list-item two-line class="cardItem">
           <v-list-item-content>
               <v-list-item-title class="headlineCardChiliad mb-lg-2 mb-md-1 mt-lg-1 mt-md-1">{{ bigNum }}
                   <span class="headlineCardHundred">{{ smallNum }}</span>
                   <span class="headlineCardHundred"> {{ unit }}</span>
                   <span class="headlineCardHundred"> {{ symbol }}</span>
               </v-list-item-title>
               <v-list-item class="subtitleCard mt-lg-2 mt-md-0 mb-lg-6 mb-md-5 mb-sm-12 text-center">
                   <p class="mx-auto">{{ text }}</p>
               </v-list-item>
        </v-list-item-content>
      </v-list-item>
    </v-card>
</template>
<script>
import '../../plugins/i18n.js'
export default {
  props: [ 'text', 'bigNum', 'smallNum', 'unit', 'symbol', 'svgPath' ],
  data () {
    return {
      locale: 'ru',
      absolute: true,
      overlay: false
    }
  },
  methods: {
    changeLocaleRu () { this.$i18n.locale = 'ru' },
    changeLocaleEn () { this.$i18n.locale = 'en-US' }
  }
}
</script>
