<template>
    <v-container fluid class="no-indentation">
        <v-row class="no-indentation mt-2 mr-2">
            <v-col col="11" lg="8" md="10" sm="10" class="pl-2 contentDoc">
                <p>{{ content }}</p>
            </v-col>
            <v-col col="1" lg="4" md="2" sm="2" class="contentDoc">
                <a :href='href' class="contentDocView" target="_blank">{{ $t('message.linkView') }}</a>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
  props: ['title', 'subtitle', 'content', 'href'],
  name: 'ContentText'
}
</script>
