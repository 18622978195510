<template>
    <div class="contentText">
        <v-form ref="form"
                v-model="valid"
                lazy-validation
        >
            <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                    <v-text-field v-model="fio"
                                  :counter="50"
                                  :rules="nameRules"
                                  :label="$t('message.careerWorkForm1')"
                                  class="inputStyle"
                                  prepend-inner-icon="perm_identity"
                                  required
                                  outlined
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                    <v-text-field v-model="vacantion"
                                  :counter="50"
                                  :rules="nameRules"
                                  :label="$t('message.careerWorkForm2')"
                                  prepend-inner-icon="work_outline"
                                  class="inputStyle"
                                  required
                                  outlined
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                    <v-text-field v-model="city"
                                  :label="$t('message.careerWorkForm3')"
                                  class="inputStyle"
                                  prepend-inner-icon="location_city"
                                  outlined
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                    <v-file-input :label="$t('message.careerWorkForm4')"
                                  @change="onUploadFiles"
                                  class="inputStyle"
                                  small-chips
                                  outlined
                                  prepend-inner-icon="attach_file"
                                  prepend-icon=""
                                  multiple
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                    <v-text-field v-model="email"
                                  :rules="emailRules"
                                  label="E-mail"
                                  prepend-inner-icon="email"
                                  class="inputStyle"
                                  required
                                  outlined
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col ccols="12" lg="12" md="12" sm="12">
                    <VuePhoneNumberInput v-model="phone"
                                         v-on:update="updatePhone"
                                         v-bind:translations="myTranslation"
                                         required
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="12">
                    <v-textarea class="inputStyle mt-12"
                                :label="$t('message.careerWorkForm5')"
                                outlined
                                rows="6"
                                v-model="text"
                                prepend-inner-icon="comment"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" lg="6" md="7" sm="6">
                    <v-btn outlined
                           x-large
                           v-on:click="send()"
                           color="blue darken-2"
                    >{{ $t('message.careerWorkForm6') }}</v-btn>
                    <v-dialog v-model="dialog"
                              width="300"
                    >
                        <v-card
                        >
                            <v-card-text class="pt-6 pb-8 circle-check">
                                <i class="material-icons mr-5 mt-3 ml-2">check_circle</i>
                                <p>{{ $t('message.careerWorkForm9') }}</p>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialog_error"
                              width="300"
                    >
                        <v-card
                        >
                            <v-card-text class="pt-6 pb-8 circle-check">
                                <i class="cansel-icon material-icons mr-5 mt-3 ml-2">cancel</i>
                                <p>{{ $t('message.careerWorkForm10') }}</p>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-col>
                <v-col cols="12" lg="6" md="5" sm="6">
                    <span class="remark">{{ $t('message.careerWorkForm7') }} <a href="#">{{ $t('message.careerWorkForm8') }}</a>.</span>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
export default {
  components: { VuePhoneNumberInput },
  methods: { updatePhone: function (value) { },
    onUploadFiles: function (files) { this.files = files },
    send: function () {
      if (this.validateForm) {
        this.$store.dispatch('mail/SEND_WORKFORM', {
          'fio': this.fio,
          'vacantion': this.vacantion,
          'city': this.city,
          'phone': this.phone,
          'email': this.email,
          'text': this.text,
          'files': this.files
        })
      } else { this.$store.dispatch('mail/SHOW_WORKFORMERROR') }
    }
  },
  computed: {
    validateForm: function () {
      if (!this.fio || !(this.fio.length < 50)) { return false }
      if (!this.vacantion || !(this.vacantion.length < 50)) { return false }
      if (!this.email || !/.+@.+\..+/.test(this.email)) { return false }
      return true
    },

    myTranslation () {
      return {
        countrySelectorLabel: this.$t('message.partnershipForSuppliersForm6'),
        countrySelectorError: this.$t('message.partnershipForSuppliersForm6Error'),
        phoneNumberLabel: this.$t('message.partnershipForSuppliersForm7'),
        example: this.$t('message.partnershipForSuppliersForm7Example')
      }
    },
    dialog: {
      get () { return this.$store.state['mail']['workformLoad'] },
      set (value) {
        this.fio = ''
        this.vacantion = ''
        this.city = ''
        this.phone = ''
        this.email = ''
        this.text = ''
        this.files = []
        this.$store.dispatch('mail/HIDE_FORM', 'workformLoad')
      }
    },
    dialog_error: {
      get () {
        return this.$store.state['mail']['workformError']
      },
      set (value) {
        this.$store.dispatch('mail/HIDE_FORM', 'workformError')
      }
    }
  },
  name: 'WorkForm',
  data () {
    return {
      valid: true,
      fio: '',
      vacantion: '',
      city: '',
      text: '',
      files: [],
      nameRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length <= 50) || 'Имя должно содержать не более 50 символов'
      ],
      email: '',
      emailRules: [
        v => !!v || 'Обязательное поле',
        v => /.+@.+\..+/.test(v) || 'Электронная почта должна быть действительной'
      ],
      phone: ''
    }
  }
}
</script>

<style scoped>

</style>
