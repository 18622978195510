<template>
    <div>
        <h1 class="contentHeading">{{ title }}</h1>
        <div class="contentText">
            {{ content }}
        </div>
    </div>
</template>
<script>
export default {
  props: ['title', 'content'],
  name: 'ContentText'
}
</script>
