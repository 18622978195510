<template>
  <div>
    <v-card

        md="2"
        min-width="300"
        elevation="2"
        style="margin: 0 24px;"
    >
        <v-img
            height="300"
            contain
            :src=GetImage
        ></v-img>
        <v-card-title

            @click="pushProductPage(name)"
            class="d-flex align-center justify-center mx-auto text-weight-bold"
            role="button">
          <div v-if="lang=='ru'">{{ this.name }}</div>
          <div v-if="lang=='en-US'">{{ this.en_name }}</div>
        </v-card-title>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'CardProduct',
  computed: {
    lang () { return this.$i18n.locale },
    GetImage () {
      if (String(this.img) === '') {
        if (this.lang === 'ru') return require('@/../public/img/Errors/no-image.png')
        if (this.lang === 'en-US') return require('@/../public/img/Errors/en_no-image.png')
      } else { return this.img }
    }
  },
  props: { img: String,
    name: String,
    en_name: String
  },
  methods: {
    pushProductPage (value) {
      this.$router.push('/product_page' + value)
    }
  }
}
</script>
