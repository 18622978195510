<template>
   <v-card class="logoCard"
           width="100%"
           max-width="95%"
   >
       <a :href="hrefPartner"><v-img :src="pathImg"/></a>
    </v-card>
</template>
<script>
import '../../plugins/i18n.js'
export default {
  props: ['pathImg', 'hrefPartner'],
  data () {
    return {
      locale: 'ru',
      absolute: true,
      overlay: false
    }
  },
  methods: {
    changeLocaleRu () { this.$i18n.locale = 'ru' },
    changeLocaleEn () { this.$i18n.locale = 'en-US' }
  }
}
</script>
