<template>
   <v-card class="managementCard"
           width="100%"
           max-width="95%"
   >
       <v-row class="no-indentation">
           <v-col class="no-indentation text-center">
               <v-avatar>
                   <img :src='pathImg'/>
               </v-avatar>
           </v-col>
           <v-col class="no-indentation managementDetail">
               <p class="managementFIO"
               >{{ fio }}</p>
               <p class="managementPost"
               >{{ post }}</p>
           </v-col>
       </v-row>
    </v-card>
</template>
<script>
import '../../plugins/i18n.js'
export default {
  props: [ 'pathImg', 'fio', 'post' ],
  data () {
    return {
      locale: 'ru',
      absolute: true,
      overlay: false
    }
  },
  methods: {
    changeLocaleRu () { this.$i18n.locale = 'ru' },
    changeLocaleEn () { this.$i18n.locale = 'en-US' }
  }
}
</script>
