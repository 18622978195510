import 'babel-polyfill'
import 'vuetify/dist/vuetify.min.css'
import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/i18n'
import '@/registerServiceWorker'
import '@/components'
import '@/plugins'
import Vuetify from 'vuetify'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

const vuetify = new Vuetify({
  icons: {
    iconfont: 'md' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    dark: false
  }
})

if (process.env.NODE_ENV === 'production') {
  Vue.config.silent = true
}
Vue.config.productionTip = false
Vue.use(Vuetify, VueAwesomeSwiper)

Vue.component('vue-phone-number-input', VuePhoneNumberInput)

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  vuetify,
  ...App
})
