// import * as types from '../mutation-types'
import axios from 'axios'

// state
export const state = {
  feedback: false,
  feedbackLoad: false,
  feedbackError: false,
  work: false,
  offergoodsformLoad: false,
  offergoodsformError: false,
  reportformLoad: false,
  reportformError: false,
  workformLoad: false,
  workformError: false
}

// getters
export const getters = {
  feedback: state => state.feedback,
  feedbackLoad: state => state.feedbackLoad,
  feedbackError: state => state.feedbackError,
  work: state => state.work,
  offergoodsformLoad: state => state.offergoodsformLoad,
  offergoodsformError: state => state.offergoodsformError,
  reportformLoad: state => state.reportformLoad,
  reportformError: state => state.reportformError,
  workformLoad: state => state.workformLoad,
  workformError: state => state.workformError
}

// mutations
export const mutations = {
  'SHOW_LOADER' (state, { form, value }) { state[form + 'Load'] = value },
  'SHOW_ERROR' (state, { form, value }) { state[form + 'Error'] = value },
  'SHOW_FORM' (state, { form, value }) { console.log(form); state[form] = value }

}

// actions
export const actions = {
  'SEND_FEDBACK': async function ({ commit }, { name, email, text }) {
    commit('SHOW_LOADER', { 'form': 'feedback', 'value': true })
    axios.post('/api/feed_back', {
      name: name,
      email: email,
      text: text
    })
      .then(function (response) {
        commit('SHOW_LOADER', { 'form': 'feedback', 'value': false })
        commit('SHOW_FORM', { 'form': 'feedback', 'value': false })
      })
      .catch(function (error) {
        console.log(error)
        commit('SHOW_LOADER', { 'form': 'feedback', 'value': false })
      })
  },
  'SHOW_FEDBACKERROR': async function ({ commit }) { commit('SHOW_ERROR', { 'form': 'feedback', 'value': true }) },
  'SEND_REPORTFORM': async function ({ commit }, { name, contactName, email, phone, text }) {
    commit('SHOW_LOADER', { 'form': 'reportform', 'value': true })
    axios.post('/api/report', {
      name: name,
      contact_name: contactName,
      email: email,
      phone: phone,
      text: text
    })
      .then(function (response) {
        commit('SHOW_LOADER', { 'form': 'reportform', 'value': true })
      })
      .catch(function (error) {
        console.log(error)
      })
  },
  'SHOW_REPORTFORMERROR': async function ({ commit }) { commit('SHOW_ERROR', { 'form': 'reportform', 'value': true }) },
  'SEND_OFFERGOODSFORM': async function ({ commit }, { name, company, site, region, contactName, phone, email, text, files }) {
    commit('SHOW_LOADER', { 'form': 'offergoodsform', 'value': true })
    var formData = new FormData()
    formData.append('name', name)
    formData.append('company', company)
    formData.append('site', site)
    formData.append('region', region)
    formData.append('contact_name', contactName)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('text', text)
    for (var i = 0; i < files.length; i++) {
      formData.append('files[]', files[i], files[i].name)
    }
    axios.post('/api/offer_goods', formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(function (response) {
        commit('SHOW_LOADER', { 'form': 'offergoodsform', 'value': true })
      })
      .catch(function (error) {
        console.log(error)
      })
  },
  'SHOW_OFFERGOODSFORMERROR': async function ({ commit }) { commit('SHOW_ERROR', { 'form': 'offergoodsform', 'value': true }) },
  'SEND_WORKFORM': async function ({ commit }, { fio, vacantion, city, phone, email, text, files }) {
    commit('SHOW_LOADER', { 'form': 'workform', 'value': true })
    var formData = new FormData()
    formData.append('fio', fio)
    formData.append('vacantion', vacantion)
    formData.append('city', city)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('text', text)
    for (var i = 0; i < files.length; i++) {
      formData.append('files[]', files[i], files[i].name)
    }
    axios.post('/api/work', formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(function (response) {
        commit('SHOW_LOADER', { 'form': 'workform', 'value': true })
      })
      .catch(function (error) {
        console.log(error)
      })
  },
  'SHOW_WORKFORMERROR': async function ({ commit }) { commit('SHOW_ERROR', { 'form': 'workform', 'value': true }) },
  'SHOW_FORM': async function ({ commit }, form) {
    commit('SHOW_FORM', { 'form': form, 'value': true })
  },
  'HIDE_FORM': async function ({ commit }, form) {
    commit('SHOW_FORM', { 'form': form, 'value': false })
  }
}
