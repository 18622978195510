import * as types from '../mutation-types'
import axios from 'axios'

// state
export const state = {
  limit: 10,
  offset: 0,
  filters: [],
  products: []
}

// getters
export const getters = {
  limit: state => state.limit,
  offset: state => state.offset,
  filters: state => state.filters,
  products: state => state.products,
  product: state => mnn => { return state.products.filter(product => product.mnn === mnn)[0] }
}

// mutations
export const mutations = {
  [types.SET_PRODUCTS] (state, { products }) { state.products = products },
  [types.SET_LIMIT_AND_OFFSET] (state, { limit, offset, filters }) {
    if (typeof (filters) !== 'undefined') { state.filters = filters }
    state.limit = limit
    state.offset = offset
    this.dispatch('news/GET_PRODUCTS', { 'filters': state.filters, 'limit': state.limit, 'offset': state.offset })
  }

}

// actions
export const actions = {
  'GET_PRODUCTS': async function ({ commit }, { filter, limit, offset }) {
    const { data } = await axios.post(
      '/api/products',
      { 'filters': state.filters, 'limit': limit, 'offset': offset }
    )
    commit(types.SET_PRODUCTS, { products: data['result'] })
  }
}
