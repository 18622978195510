<template>
    <div>
        <v-btn block class="mx-auto subMenuCard text-center"
               width="100%"
               outlined
               v-on:click="push"
        >
            <v-icon class="my-lg-6 my-md-5 my-sm-5">{{ svgPath }}</v-icon>
            <h5 class="my-lg-6 my-md-5 my-sm-5">{{ submenu }}</h5>
        </v-btn>
    </div>
</template>
<script>
import '../../plugins/i18n.js'
export default {
  props: ['submenu', 'push', 'svgPath'],
  data () {
    return {
    }
  },
  methods: {
    changeLocaleRu () { this.$i18n.locale = 'ru' },
    changeLocaleEn () { this.$i18n.locale = 'en-US' }
  }
}
</script>
