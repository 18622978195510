import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: store.getters['lang/locale'],
  messages: {
    'ru': {
      message: {
        companyName: 'СмолМедПрепараты',
        slogan: 'с заботой о Вашем здоровье',
        linkView: 'Смотреть',
        submenu1: 'О компании',
        submenu2: 'Наши ' +
        'Продукты',
        submenu3: 'Продукты ' +
        'в проекте',
        submenu4: 'Партнерство',
        submenu5: 'Карьера',
        submenu6: 'Контакты',
        submenu7: 'Продукты',
        sliderHeader1: 'Компания',
        sliderHeader2: 'Деятельность',
        sliderHeader3: 'Сотрудничество',
        sliderText1: 'ООО "СмолМедПрепараты" организована в августе 2020 года, входит в группу компаний «Фармацевтика» ' +
            'и является резидентом индустриального парка "Феникс" г. Смоленск',
        sliderText2: 'ООО "СмолМедПрепараты" - Отечественная фармацевтическая компания, осуществляющая производство ' +
            'готовых лекарственных форм и владеющая передовыми технологиями по производству лекарственных препаратов.',
        sliderText3: 'За свою деятельность наша компания установила партнерские отношения со многими  отечественными и ' +
            'зарубежными производителями, а так же плодотворно ' +
            'сотрудничает с фармацетическими дистрибьютерами лекарственных препаратов ',
        sliderButton1: 'Узнать подробнее',
        sliderButton2: 'Узнать подробнее',
        sliderButton3: 'Узнать подробнее',
        allNews: 'Все новости',
        contentUnit1: 'тыс.',
        contentUnit2: 'кв.м',
        contentUnit3: 'млрд.руб.',
        contentUnit4: 'куб.м',
        contentUnit5: '%',
        contentUnit6: 'млн.куб.м',
        contentUnit7: 'млн. руб.',
        contentUnit8: 'тыс. руб.',
        contentNumericHead: 'Компания в цифрах',
        contentNumeric1: 'Приблизительно 15 млн. руб. - отчисления в различные фонды',
        contentNumeric2: 'Высокотехнологичные, лицензированные склады',
        contentNumeric3: 'Ежегодный оборот: более 1 млрд. руб.',
        contentNumeric4: 'Более 95 сотрудников',
        contentNum5: 'Достаточная',
        contentNumeric5: 'Доля на рынке',
        contentNum6: 'Россия, Белоруссия',
        contentNumeric6: 'Охват ООО СмолМедПрепараты',
        contentNumeric7: 'Планируемый доход компании в размере 1 млрд. руб.',
        contentNumeric8: '50 свободных рабочих мест',
        contentNumeric9: 'Заработная плата не менее 70 тыс. руб.',
        contentNumeric10: 'НДС будет приносить около 100 млн. руб.',
        contentNumeric11: 'Ежегодная прибыль 60 млн. руб. в виде налога на прибыль',
        contentNumeric12: 'Высокотехнологичные производственные помещения 6000 кв.м.',
        aboutUsSubmenu1: 'Руководство',
        aboutUsManagementFIO1: 'Сингх Кумар Абхай',
        aboutUsManagementPost1: 'Генеральный директор',
        aboutUsManagementFIO2: 'Каплунова Наталья Владимировна',
        aboutUsManagementPost2: 'Директор по производству',
        aboutUsManagementFIO3: 'Сингх Елена Борисова',
        aboutUsManagementPost3: 'Заведующий отделом снабжения',
        aboutUsManagementFIO4: 'Байкова Лилия Леонидовна',
        aboutUsManagementPost4: 'Главный бухгалтер',
        aboutUsManagementFIO5: 'Воробьев Олег Викторович',
        aboutUsManagementPost5: 'Администратор-инженер по строительству',
        aboutUsManagementFIO6: 'Устинов Александр Борисович',
        aboutUsManagementPost6: 'Программист',
        aboutUsManagementFIO7: 'Семейкин Алексей Вадимович',
        aboutUsManagementPost7: 'Заместитель генерального директора',
        aboutUsSubmenu2: 'Документы',
        aboutUsSubmenu3: 'Виды деятельности',
        aboutUsSubmenu31: 'Лицензии и виды деятельности',
        aboutUsTypesOfActivitiesSveden: 'Сведения о видах деятельности организации в соответствии с данными ЕГРЮЛ',
        aboutUsTypesOfActivitiesEGRUL: 'Единый государственный реестр юридических лиц ФНС России',
        aboutUsTypesOfActivitiesOsnTitle: 'Основной',
        aboutUsTypesOfActivitiesOsn: ' Производство лекарственных препаратов для медицинского применения',
        aboutUsTypesOfActivitiesDop: 'Дополнительные',
        aboutUsTypesOfActivitiesDop1: 'Оптовая торговля лекарственными средствами для медицинского применения',
        aboutUsTypesOfActivitiesDop2: 'Хранение лекарственных средств и препаратов для медицинского применения',
        aboutUsSubmenu4: 'Антикоррупционная политика',
        aboutUsSubmenu5: 'Новости компании',
        aboutUsText1: 'ООО «СмолМедПрепараты» - российская фармацевтическая компания, организована в августе 2020 года. ' +
            'Входит в группу компаний «Фармацевтика» и является резидентом индустриального парка «Феникс» г.Смоленск. ' +
            'Главное направление деятельности компании - выпуск эффективных, доступных по цене и высококачественных ' +
            'лекарственных препаратов наиболее востребованных российскими пациентами терапевтических групп.',
        aboutUsText2: 'Компания плотно сотрудничает с лечебными учреждениями региона, обеспечивая поставки необходимых ' +
            'лекарственных препаратов по лучшим ценам.',
        aboutUsText3: 'Все помещения производственного участка спроектированы и оборудованы в соответствии с требованиями ' +
            'GMP и требованиями к помещениям различного класса чистоты.',
        aboutUsText4: 'GMP (Good Manufacturing Practice, Надлежащая производственная практика) – международный стандарт, ' +
            'который устанавливает требования к производству и контролю качества лекарственных средств для человека и ' +
            'животных, а также специальные требования к производству активных фармацевтических субстанций и отдельных ' +
            'видов лекарственных средств. Стандарт GMP регулирует и оценивает параметры производства и лабораторной проверки.',
        aboutUsText5: 'Национальным аналогом GMP в РФ является стандарт ГОСТ Р 52249–2009 «Правила производства и контроля ' +
            'качества лекарственных средств», текст которого идентичен GMP.',
        aboutUsSubTitle1: 'Учредительные документы',
        aboutUsSubTitle2: 'Внутренние документы общества',
        aboutUsSubTitle3: 'Юридические документы',
        aboutUsDocument1: 'Устав ООО "СмолМедПрепараты"',
        aboutUsDocument2: 'Решение о создании и назначении генерального директора от 06.08.2020',
        aboutUsDocument3: 'Генеральный план "СмолМедПрепараты"',
        aboutUsDocument4: 'Соглашение с администрацией о ведении хозяйственной деятельности',
        aboutUsDocument5: 'Лист записи Единого государственного реестра юридицеских лиц',
        aboutUsDocument6: 'Свидетельство о постановке на учет в налоговом органе ООО "СмолМедПрепараты" от 11.08.2020',
        aboutUsDocument7: 'Реквизиты ООО "СмолМедПрепараты"',
        aboutUsDocument1HREF: '/file/Ustav.pdf',
        aboutUsDocument2HREF: '/file/Reshenie_sozdaniyz_i_naznacheniya_gen_dir.pdf',
        aboutUsDocument3HREF: '/file/Gen_Plan.pdf',
        aboutUsDocument4HREF: '/file/Soglashenie_s_admin.pdf',
        aboutUsDocument5HREF: '/file/EGRUL.pdf',
        aboutUsDocument6HREF: '/file/Svidetelstvo.pdf',
        aboutUsDocument7HREF: '/file/Rekvizity.docx',
        aboutUsAntiCorruption1: 'Деятельность нашей компании построена на поддержании авторитета и доверия к ней ' +
            'обязательными к исполнению всеми сотрудниками инструкциями, утвержденными в следующих внутренних документах: ',
        aboutUsAntiCorruption2: '\n' +
            '                  Для осуществления эффективной политики руководству компании необходимо знать обо всех нарушениях.\n' +
            '                  Если вам стало известно о каких-либо фактах совершения коррупционных действий или склонения к их\n' +
            '                  совершению сотрудниками нашей компании, либо третьими лицами от лица «Фарм-Инноваций», либо о фактах\n' +
            '                  совершения коррупционных действий контрагентами или третьими лицами в отношении интересов\n' +
            '                  «СмолМедПрепараты», пожалуйста, напишите нам на ',
        aboutUsAntiCorruption3: 'Мы гарантируем конфиденциальность вашего обращения.',
        aboutUsAntiCorruption4: 'По возможности, пожалуйста, укажите в своем обращении следующую информацию: ',
        aboutUsAntiCorruption5: 'Ваши контактные данные (ФИО, телефон, email);',
        aboutUsAntiCorruption6: 'ФИО и должность сотрудника, которого склоняют к совершению коррупционных правонарушений\n' +
            '                              или который участвовал в совершении коррупционных правонарушений;',
        aboutUsAntiCorruption7: 'описание обстоятельств, при которых стало известно о совершении или склонении к\n' +
            '                              совершению коррупционного правонарушения (дата, место, время и тд);',
        aboutUsAntiCorruption8: 'подробное описание факта коррупционного правонарушения;',
        aboutUsAntiCorruption9: 'сведения о лице, склоняющем к коррупционному правонарушению;',
        aboutUsAntiCorruption10: 'информация об отказе (согласии) принять предложение.',
        partnershipEcologyText1: 'Выбросы загрязняющих веществ в атмосферу ',
        partnershipEcologyText2: 'С точки зрения воздействия выбросов на качество атмосферного воздуха для производства не требует СЗЗ.',
        partnershipEcologyText3: 'Все получаемые в результате производства отходы утилизируются специализированными компаниями, которые имеют соответствующие лицензии, тем самым не наносится вред окружающей среде',
        partnershipEcologyText4: 'Годовые нормативы образования отходов производства - ',
        partnershipEcologyText5: 'Бытовые отходы составляют - ',
        partnershipEcologyTextSelect1: 'ИСКЛЮЧЕНЫ',
        partnershipEcologyTextSelect2: '12 т/год',
        partnershipEcologyTextSelect3: '1 т/год',
        partnershipEffectContent1: 'Одна из ключевых идей данного проекта - обеспечить социально-ориентированное производство жизненно необходимых препаратов',
        partnershipEffectNumber1: '22',
        partnershipEffectNumber2: '40',
        partnershipEffectNumber3: '15',
        partnershipEffectNumber4: '~1',
        partnershipEffectNumber5: '~100',
        partnershipEffectNumber6: '60',
        partnershipEffectTextWithNumber1: 'раб. мест',
        partnershipEffectTextWithNumber2: 'тыс. руб.',
        partnershipEffectTextWithNumber3: 'млн. руб.',
        partnershipEffectTextWithNumber4: 'млрд. руб.',
        partnershipEffectTextWithNumber5: 'млн. руб.',
        partnershipEffectTextWithNumber6: 'млн. руб.',
        partnershipEffectText1: 'Реализация проекта обеспечит дополнительные рабочие места для 22 человек со ' +
            'средней заработной платой не менее 40 тысяч рублей, отчисления с которых в различные фонды составят ' +
            'приблизительно 15 миллионов рублей.',
        partnershipEffectText2: 'Компания планирует доход в 1 миллиард рублей, при этом НДС будет приносить около ' +
            '100 миллионов рублей. Компания планирует получить прибыль в размере 30 %, что составит 60 миллионов ' +
            'рублей в виде налога на прибыль ежегодно.',
        partnershipEffectText3part1: 'Данные препараты являются препаратами стратегического назначения,',
        partnershipEffectText3part2: ' а значит локализация их производства дает стимул развития процесса',
        partnershipEffectText3part3: ' импортозамещения ',
        partnershipEffectText3part4: 'в фармацевтической области.',
        partnershipEffectTextLeftBorder1: 'Квалифицированный персонал планируется привлекать на конкурсной основе за счет ресурсов ' +
            'Смоленской области и соседних областей. ',
        partnershipEffectTextLeftBorder2beforespan: 'Компания «Фарм-Инновация», входящая в ГК «Фармацевтика», и являющаяся её инвестором, уже заключила договор о научно-исследовательских работах с ',
        partnershipEffectTextLeftBorder2span: 'Санкт-Петербургской государственным химико-фармацевтической университетом',
        partnershipEffectTextLeftBorder2afterspan: ', где так же планируется проводить повышение квалификации специалистов.',
        partnershipEffectTextBlack1beforespan: 'Компания «СмолМедПрепараты» намерена заложить',
        partnershipEffectTextBlack1span: 'мощнейший фундамент для фармацевтического кластера',
        partnershipEffectTextBlack1afterspan: ' на территории Смоленской области. ',
        partnershipEffectTextBlack2: 'Резкое снижение стоимости для конечного потребителя',
        partnershipHead: 'Инвестиционный проект',
        partnershipSubHead1: 'Географическое преимущество',
        partnershipSubHead2: 'Бизнес-модель продвижения новых дженериков в Российской Федерации',
        partnershipSubHead3: 'Сервисы',
        partnershipSubHead4: 'Рекламные возможности',
        partnershipSubHead5: 'Предложить товар',
        partnershipSubHead6: 'Отчетность',
        partnershipSchemeSRC: '/img/scheme/scheme.png',
        partnershipSubmenu1: 'Экологическая безопасность',
        partnershipSubmenu2: 'Поставщикам',
        partnershipSubmenu3: 'Наши партнеры',
        partnershipSubmenu4: 'Эффективность проекта',
        partnershipSubmenu5: 'Наши продукты',
        partnershipSubmenu6: 'Продукты в проекте',
        partnershipText1: 'Смоленская область - важнейший транспортный и коммуникационный узел. Через него проходят ' +
            'кратчайшие автомобильные и железнодорожные магистрали, связывающие Западную Европу с Центральной Россией. ' +
            'Такое расположение дает уникальные возможности с точки зрения оптимизации логистических процессов нашей ' +
            'компании',
        partnershipText2: 'С точки зрения воздействия выбросов на качество атмосферного воздуха производство относится ' +
            'к V (пятой) категории, выбросы загрязняющих веществ в атмосферу исключены.',
        partnershipText3: 'Бытовые отходы составляют 1 т/год. Годовые нормативы образования отходов производства - 12 т/год.',
        partnershipText4: 'Все получаемые в результате производства отходы утилизируются специализированными компаниями, ' +
            'которые имеют соответствующие лицензии, тем самым нанесение вреда окружающей среде исключается.',
        partnershipText5: '- распределительная логистика;',
        partnershipText6: '- сбытовая логистика;',
        partnershipText7: '- транспортная логистика;',
        partnershipText8: '- складская логистика;',
        partnershipText9: '- информационная логистика.',
        partnershipText10: 'крупнейший в Смоленской области онлайн сервис поиска и заказа',
        partnershipText11: 'лекарственных средств, БАДов, изделий медицинского назначения и косметической продукции.',
        partnershipText12: 'Площадка Интернет-аптеки предоставляет широкий спектр рекламных услуг для продвижения товара',
        partnershipText13: 'заказ',
        partnershipText14: '- формирование пользователем Интернет-аптеки заявки на товар с последующим его выкупом в одном из\n' +
            '                  аптечных пунктов сети "Городская аптека". Товар и аптечный пункт выбирается пользователем самостоятельно\n' +
            '                  из представленных на сайте',
        partnershipText15: 'Любые виды рекламы на собственных площадках и на сайтах партнёров',
        partnershipText16: 'Продвижение товаров и услуг на платформах социальных сетей компании и партнёров',
        partnershipText17: 'Баннерная реклама во многих точках города Смоленска и Смоленской области',
        partnershipText18: 'Любые виды наружной и прямой рекламы',
        partnershipText19: 'По вопросам продвижения ассортимента на ',
        partnershipText20: 'напишите нам',
        partnershipText21: 'или позвоните по телефону ',
        partnershipText22: 'Мы всегда открыты для взаимовыгодного партнерства.\n' +
            '                  Для начала сотрудничества с нашей компанией Вы можете сделать заявку на включение товара в наш прайс-лист,\n' +
            '                  заполнив следующую форму',
        partnershipText23: 'Для формирования заявки на получение информации по продажам и товарному запасу, пожалуйста, заполните форму. ' +
            'На все ваши вопросы мы с удовольствием ответим по телефону',
        partnershipText24: 'Строительство фармацевтического завода полного цикла производства лекарственных препаратов',
        partnershipText25: 'В результате реализации проекта возможно расширение существующего ассортимента лекарственных средств ' +
        'в связи с измененнием спроса на рынке, внедрением новых технологий и прочих факторов',
        partnershipText26: 'Планируемое место реализации:\n',
        partnershipBoldText1: 'областной государственный индустриальный парк «Феникс».',
        partnershipLogisticsNumericHead: 'Логистическая система в цифрах',
        partnershipWarehouseNumericHead: 'Складская система в цифрах',
        partnershipLogisticsSubHead1: 'Производственные мощности и технологии',
        partnershipNumeric1: 'Более 1000 точек доставки в год',
        partnershipNumeric2: 'Среднее количество точек доставки по Смоленску и области в день',
        partnershipNumeric3: 'Автомобилей для доставки в день',
        partnershipNumeric4: 'Объем обработанного товара',
        partnershipNumeric5: 'Затраты на складскую логистику',
        partnershipNumeric7: 'Общая ёмкость склада',
        partnershipNumeric8: 'Объем поступлений на склад',
        partnershipNumeric9: 'Отгрузка со склада',
        partnershipForSuppliersForm1: 'Название товара',
        partnershipForSuppliersForm2: 'Компания',
        partnershipForSuppliersForm3: 'Сайт вашей компании',
        partnershipForSuppliersForm4: 'Регион',
        partnershipForSuppliersForm5: 'Контактное лицо',
        partnershipForSuppliersForm6: 'Код страны',
        partnershipForSuppliersForm6Error: 'Выберите код страны',
        partnershipForSuppliersForm7: 'Телефон',
        partnershipForSuppliersForm7Example: 'пример: ',
        partnershipForSuppliersForm8: 'Прикрепить файлы',
        partnershipForSuppliersForm9: 'Комментарий',
        partnershipForSuppliersForm10: 'Отправить',
        partnershipForSuppliersForm11: 'Содержание заявки',
        partnershipForSuppliersForm12: 'Сформировать заявку',
        partnershipForSuppliersForm13: 'Заявка сформирована!',
        partnershipForSuppliersForm14: 'Вы не заполнили форму правильно',
        partnershipOurPartnersSubHead1: 'Крупнейшие поставщики ГЛС',
        partnershipOurPartnersText: 'Более 100 ведущих фармпроизводителей',
        partnershipOurPartnersSubHead2: 'Крупнейшие поставщики НЛС',
        partnershipOurPartnersSubHead3: 'Крупнейшие поставщики <<...>>',
        careerSubHead: 'Карьера в компании',
        careerSubTitle1: 'Мы предлагаем: ',
        careerSubTitle2: 'Вопросы по трудоустройству?',
        careerSubTitle3: 'Вакансии',
        careerText1: 'В ООО "СмолМедПрепараты" каждый сотрудник – полноценный партнер. Мы создаем комфортные условия работы, ' +
            'дружественную атмосферу, возможности для роста и построения карьеры, достойно вознаграждаем сотрудников и ' +
            'признаем тех, кто добивается высоких результатов.',
        careerText2: 'Карьерный и профессиональный рост',
        careerText3: 'Достойную заработную плату',
        careerText4: ' Всестороннее обучение и развитие',
        careerText5: 'Контактный телефон: ',
        careerText6: 'Актуальную информацию о вакансиях компании Вы можете посмотреть на сайте',
        careerText7: 'Вы также можете прислать ваше резюме, используя следующую форму: ',
        careerWorkForm1: 'ФИО',
        careerWorkForm2: 'Вакансия',
        careerWorkForm3: 'Город',
        careerWorkForm4: 'Прикрепить файлы',
        careerWorkForm5: 'Текст сообщения',
        careerWorkForm6: 'Отправить резюме',
        careerWorkForm7: 'Нажимая кнопку «Отправить», вы соглашаетесь с',
        careerWorkForm8: ' политикой обработки персональных данных',
        careerWorkForm9: 'Ваше резюме отправлено!',
        careerWorkForm10: 'Вы неправильно заполнили резюме',
        feedBackForm: 'Обратная связь',
        feedBackForm1: 'Как к Вам обращаться?',
        feedBackForm2: 'Укажите Email для обратной связи',
        feedBackForm3: 'Текст сообщения',
        feedBackForm4: 'Вы не заполнили форму правильно',
        contactSubTitle1: 'Центральный офис',
        contactSubTitle2: 'Центральный склад',
        contactText1: 'Россия, г.Смоленск, Индустриальный парк "Феникс" д. 6',
        contactText2: 'Россия, Смоленская область, Смоленский район, с. Михновка, ул. Солнечная, 1, оф.1',
        contactPhone: 'Телефон',
        contactText3: 'Будем рады Вашим отзывам и предложениям!',
        contactText4: 'Напишите нам',
        contactText5: 'Либо заполнив',
        contactText6: 'форму обратной связи',
        contactInfo: 'Все права защищены ©, 2019-2020 ООО “СмолМедПрепараты”. Верстка и программирование: ',
        contactITMosaic: ' “ИТ-Мозаика”',
        contentProductLabel1: 'Условия отпуска',
        contentProductLabel2: 'Дозировка',
        contentProductLabel3: 'Фармако-терапевтическая группа',
        contentProductLabel4: 'Форма выпуска',
        contentProductLabel5: 'Стоимость',
        effectivnessContentMiddlePrice: 'Ср. стоимость товара -',
        effectivnessContentAfterRealization: 'После реализации проекта - не дороже ',
        effectivnessContentRub: ' руб',
        projectProductsSubTitle: 'Продукты в проекте',
        ourProductsHeading: 'Продукция',
        ourProductsSubTitle1: 'Наши продукты',
        ourProductsSubTitle2: 'Продукты в разработке'
      }
    },
    'en-US': {
      message: {
        companyName: 'SmolMedPreparaty',
        slogan: 'With Care of Your Health!',
        linkView: 'View',
        submenu1: 'About Company',
        submenu2: 'Our Products',
        submenu3: 'Products in Project',
        submenu4: 'Partnership',
        submenu5: 'Career',
        submenu6: 'Contacts',
        submenu7: 'Products',
        sliderHeader1: 'Company',
        sliderHeader2: 'Activities',
        sliderHeader3: 'Collaboration',
        sliderText1: 'Established in August 2020, SmolMedPreparaty LLC is part of the corporate group “Pharmaceutics”, and a resident of the Smolensk Phoenix Industrial Park.',
        sliderText2: 'SmolMedPreparaty LLC is a domestic pharmaceutical company that produces finished dosage forms and has advanced drug manufacturing technology.',
        sliderText3: 'In the course of activities, our company has established partnerships with many domestic and foreign manufacturers, as well as fruitful collaboration with distributors of pharmaceuticals.',
        sliderButton1: 'More details',
        sliderButton2: 'More details',
        sliderButton3: 'More details',
        allNews: 'All news',
        contentUnit1: 'thou.',
        contentUnit4: 'cbm',
        contentNumericHead: 'Company in Numbers',
        contentNumeric1: 'Contributions to various funds are approximately 15 million rubles.',
        contentNumeric2: 'High-tech, licensed warehouses',
        contentUnit2: 'sq.m',
        contentNumeric3: 'Annual turnover is more than 1 billion rubles. ',
        contentUnit3: 'billion rubles',
        contentNumeric4: 'Number of employees: more than 95 people',
        contentNum5: 'Good',
        contentNumeric5: 'Market share',
        contentUnit5: '%',
        contentNum6: 'Russia, Belarus',
        contentNumeric6: 'Coverage of LLC Pharm-innovation',
        contentUnit6: 'mln cbm',
        contentUnit7: 'mln rub',
        contentUnit8: 'thnd rub',
        contentNumeric7: 'The planned income of the company is 1 billion rubles.',
        contentNumeric8: '50 available jobs',
        contentNumeric9: 'Salary of at least 70 thousand rubles. ',
        contentNumeric10: 'VAT will bring about 100 million rubles.',
        contentNumeric11: 'Annual profit 60 million rubles. in the form of income tax',
        contentNumeric12: 'High-tech production facilities with an area of 6000 sq. m.',
        aboutUsSubmenu1: 'Management',
        aboutUsManagementFIO1: 'Abhay Kumar Singh',
        aboutUsManagementPost1: 'General Director',
        aboutUsManagementFIO2: 'Svetlana Yuryevna Kolobanova',
        aboutUsManagementPost2: 'Production Director',
        aboutUsManagementFIO3: 'Singh Elena Borisova',
        aboutUsManagementPost3: 'Head of the Supply Department',
        aboutUsManagementFIO4: 'Lilia Leonidovna Baikova',
        aboutUsManagementPost4: 'Chief Accountant',
        aboutUsManagementFIO5: 'Vladimir Ivanovich Novikov',
        aboutUsManagementPost5: 'Construction Engineer/Manager',
        aboutUsManagementFIO6: 'Alexander Borisovich Ustinov',
        aboutUsManagementPost6: 'Programmer',
        aboutUsManagementFIO7: 'Alexey Vadimovich Semeykin',
        aboutUsManagementPost7: 'Deputy Director-General',
        aboutUsSubmenu2: 'Documents',
        aboutUsSubmenu3: 'Activities',
        aboutUsSubmenu31: 'Licenses and Activities',
        aboutUsTypesOfActivitiesSveden: 'Information about the company’s activities in accordance with the Unified State Register of Legal Entities ',
        aboutUsTypesOfActivitiesEGRUL: 'Unified State Register of Legal Entities of the Federal Tax Service of Russia',
        aboutUsTypesOfActivitiesOsnTitle: 'Principal Activity',
        aboutUsTypesOfActivitiesOsn: 'Manufacturing of medicines for human use.',
        aboutUsTypesOfActivitiesDop: 'Additional activities',
        aboutUsTypesOfActivitiesDop1: 'Wholesale of medicines for human use.',
        aboutUsTypesOfActivitiesDop2: 'Storage of drugs and medicines for human use.',
        aboutUsSubmenu4: 'Anti-corruption Policy',
        aboutUsSubmenu5: 'Company News',
        aboutUsText1: 'SmolMedPreparaty LLC is a Russian pharmaceutical company established in August 2020. It\'s part of the corporate group “Pharmaceutics” and a resident of the Smolensk Phoenix Industrial Park. The main business area of the Company is the production of effective, affordable, and high-quality medicines for therapeutic groups, highly demanded by Russian patients.',
        aboutUsText2: 'The company closely cooperates with medical institutions in the region, ensuring the supply of ' +
            'necessary medicines at the best prices according to the results of auctions.',
        aboutUsText3: 'All manufacturing premises are designed and equipped in accordance ' +
            'with GMP requirements and requirements for different cleanroom classes.',
        aboutUsText4: 'GMP (Good Manufacturing Practice) is an international standard that ' +
            'establishes requirements for the manufacturing and quality control of drugs' +
            ' for humans and animals, as well as specific requirements for the manufacturing' +
            ' of active pharmaceutical ingredients and certain types of drugs. The GMP' +
            ' standard regulates and evaluates production and laboratory testing parameters.',
        aboutUsText5: 'The national analog of GMP in the Russian Federation is GOST R 52249-2009 ' +
            '“Rules for Manufacturing and Quality Control of Medicinal Products,” the text of which is identical to GMP.',
        aboutUsSubTitle1: 'Constituent documents',
        aboutUsSubTitle2: 'Internal documents of the company',
        aboutUsSubTitle3: 'Legal Documents',
        aboutUsDocument1: 'Constitution of SmolMedPreparaty LLC',
        aboutUsDocument2: 'Decision on establishment and appointment of Director-General dated August 06, 2020.',
        aboutUsDocument3: 'Site plan of SmolMedPreparaty LLC',
        aboutUsDocument4: 'Agreement with the city administration to conduct business activities.',
        aboutUsDocument5: 'Record sheet of the Unified State Register of Legal Entities',
        aboutUsDocument6: 'Certificate of registration with the tax authority of SmolMedPrepareparaty LLC dated August 11th, 2020.',
        aboutUsDocument7: 'Details of SmolMedPrepareparaty LLC',
        aboutUsDocument1HREF: '/file/Charter.pdf',
        aboutUsDocument2HREF: '/file/Reshenie_sozdaniyz_i_naznacheniya_gen_dir.pdf',
        aboutUsDocument3HREF: '/file/Gen_Plan.pdf',
        aboutUsDocument4HREF: '/file/Agreement 24.08.20.pdf',
        aboutUsDocument5HREF: '/file/EGRUL.pdf',
        aboutUsDocument6HREF: '/file/certificate.pdf',
        aboutUsDocument7HREF: '/file/Corporate card.pdf',
        aboutUsAntiCorruption1: 'Our company’s activities are built on maintaining the authority and credibility ' +
            'of the company by mandatory instructions for all employees approved in the following internal documents: ',
        aboutUsAntiCorruption2: 'To implement an effective policy, our company’s management needs to be aware of ' +
            'all violations. If you become aware of any facts of corrupt practices or inducement to commit them by ' +
            'our employees or third parties on behalf of “Pharm-Innovations,” or facts of corrupt practices by ' +
            'counterparties, or third parties, concerning the interests of ' +
            'the SmolMedPreparaty company, please email us at ',
        aboutUsAntiCorruption3: 'We protect the confidentiality of your request.',
        aboutUsAntiCorruption4: 'If possible, please include the following information upon your request: ',
        aboutUsAntiCorruption5: 'Your contact information (full name, phone number, email).',
        aboutUsAntiCorruption6: 'Name and position of the employee who is induced to commit ' +
            'corruption offenses, or who participated in the commission of corrupt offenses.',
        aboutUsAntiCorruption7: 'Description of the circumstances in which it became known ' +
            'about the commission or inducement to commit corrupt offenses (date, place, time, etc.).',
        aboutUsAntiCorruption8: 'A detailed description of the fact of the corrupt offenses.',
        aboutUsAntiCorruption9: 'Information about the person who incited the corrupt offenses.',
        aboutUsAntiCorruption10: 'Information about the refusal (consent) to accept the offer.',
        partnershipEffectContent1: 'One of the key ideas of this project is to provide socially ' +
            'oriented manufacturing of essential medicines.',
        partnershipEffectNumber1: '22',
        partnershipEffectNumber2: '40',
        partnershipEffectNumber3: '15',
        partnershipEffectNumber4: '~1',
        partnershipEffectNumber5: '~100',
        partnershipEffectNumber6: '60',
        partnershipEffectTextWithNumber1: 'jobs',
        partnershipEffectTextWithNumber2: 'thousand rubles',
        partnershipEffectTextWithNumber3: 'million rubles',
        partnershipEffectTextWithNumber4: 'billion rubles',
        partnershipEffectTextWithNumber5: 'million rubles',
        partnershipEffectTextWithNumber6: 'million rubles',
        partnershipEffectText1: 'The project implementation will provide additional jobs for 22 people with ' +
            'an average salary of at least 40,000 rubles, the reductions from various funds will be' +
            ' approximately 15 million rubles.',
        partnershipEffectText2: 'The planned corporate income is 1 billion rubles, and the VAT will bring about ' +
            '100 million rubles. The company plans to make a 30% profit, which will amount to 60 million ' +
            'rubles in profit tax annually.',
        partnershipEffectText3part1: 'These drugs are strategic goods,',
        partnershipEffectText3part2: ' which means that the localization of their production gives an incentive ' +
            'to develop the process of',
        partnershipEffectText3part3: ' import substitution ',
        partnershipEffectText3part4: 'in the pharmaceutical field.',
        partnershipEffectTextLeftBorder1: 'It is planned to attract qualified personnel on a competitive basis' +
            ' from the resources of the Smolensk and neighboring regions.',
        partnershipEffectTextLeftBorder2beforespan: 'The “Pharm-Innovation” company, which is part of the corporate ' +
            'group “Pharmaceutics” and is its investor, has already signed a research contract with the ',
        partnershipEffectTextLeftBorder2span: 'St. Petersburg State Chemical Pharmaceutical University',
        partnershipEffectTextLeftBorder2afterspan: ', where it also plans to conduct advanced training of specialists.',
        partnershipEffectTextBlack1beforespan: 'The company "SmolMedPreparaty" intends to lay a ',
        partnershipEffectTextBlack1span: 'powerful foundation for a pharmaceutical cluster',
        partnershipEffectTextBlack1afterspan: ' in the Smolensk Region.',
        partnershipEffectTextBlack2: 'Drastic cost reduction for the end user',
        partnershipHead: 'Investment Project',
        partnershipSubHead1: 'Geographical advantage',
        partnershipSubHead2: 'Business model of promoting new generic drugs in the Russian Federation',
        partnershipSubHead3: 'Services',
        partnershipSubHead4: 'Advertising opportunities',
        partnershipSubHead5: 'Suggest a product',
        partnershipSubHead6: 'Reporting',
        partnershipSchemeSRC: '/img/scheme/scheme.png',
        partnershipSubmenu1: 'Environmental Safety',
        partnershipSubmenu2: 'Security of ecologic',
        partnershipSubmenu3: 'Our Partners',
        partnershipSubmenu4: 'Project Efficiency',
        partnershipSubmenu5: 'Effectiveness of project',
        partnershipSubmenu6: 'Products in Project',
        partnershipText1: 'Smolensk region is the most important transport and communication hub. The shortest ' +
            'highways and railways connecting Western Europe with Central Russia pass through it. This arrangement ' +
            'provides unique opportunities in terms of optimizing the logistics processes of our company.',
        partnershipText2: 'Thanks to thoughtful logistics, the company Pharm-Innovation LLC chooses the most effective ' +
            'options for providing goods with the right quality and quantity, at the right time, in the right place and ' +
            'at the lowest cost.',
        partnershipText3: 'Domestic waste is 1 ton per year. The annual standards for production waste generation ' +
            'are 12 ton per year.',
        partnershipText4: 'All production waste is disposed of by specialized companies that have the appropriate ' +
            'licenses, thereby avoiding damage to the environment.',
        partnershipText5: '- distribution logistics;',
        partnershipText6: '- sales logistics;',
        partnershipText7: '- transport logistics;',
        partnershipText8: '- warehouse logistics;',
        partnershipText9: '- information logistics.',
        partnershipText10: 'the largest online service in the Smolensk region for searching and ordering',
        partnershipText11: 'medicines, dietary supplements, medical devices and cosmetic products',
        partnershipText12: 'The online pharmacy platform provides a wide range of advertising services for product promotion.',
        partnershipText13: 'order',
        partnershipText14: '- the formation by the user of an online pharmacy of an application for a product with its ' +
            'subsequent redemption at one of the pharmacy points of the City Pharmacy network. The product and pharmacy ' +
            'is selected by the user independently from those presented on the site',
        partnershipText15: 'Any types of advertising on their own sites and on partner sites',
        partnershipText16: 'Promotion of goods and services on the platforms of social networks of the company and partners',
        partnershipText17: 'Banner advertising in many points of the city of Smolensk and the Smolensk region',
        partnershipText18: 'Any types of outdoor and direct advertising',
        partnershipText19: 'Regarding the promotion of the assortment at ',
        partnershipText20: 'write to us',
        partnershipText21: 'or call ',
        partnershipText22: 'We are always open for mutually beneficial partnership. To start cooperation with our company, ' +
            'you can make an application for inclusion of goods in our price list by filling out the following form',
        partnershipText23: 'To generate an application for information on sales and inventory, please fill out the form.' +
            'We will be happy to answer all your questions by phone',
        partnershipText24: 'Construction of a full-scale pharmaceutical plant.',
        partnershipText25: 'As a result of the project implementation, it is possible to expand ' +
            'the existing range of drugs due to changes in market demand, the introduction of new technologies, and other facts.',
        partnershipText26: 'Planned place of implementation:\n',
        partnershipBoldText1: 'the regional state industrial park “Phoenix".',
        partnershipSubTitle: 'Logistics',
        partnershipLogisticsNumericHead: 'Logistics system in numbers',
        partnershipWarehouseNumericHead: 'Warehouse system in numbers',
        partnershipNumeric1: 'Over 1000 delivery points per year',
        partnershipNumeric2: 'The average number of delivery points in Smolensk and the region per day',
        partnershipNumeric3: 'Number of vehicles used for the delivery per day',
        partnershipNumeric4: 'Volume of product handled',
        partnershipNumeric5: 'Expenses for warehouse logistics',
        partnershipNumeric7: 'Total capacity of the warehouse',
        partnershipNumeric8: 'Input volume in the warehouse',
        partnershipNumeric9: 'Shipment from the warehouse',
        partnershipLogisticsSubHead1: 'Production capacity and technologies',
        partnershipForSuppliersForm1: 'Product Name',
        partnershipForSuppliersForm2: 'Company',
        partnershipForSuppliersForm3: 'Your company website',
        partnershipForSuppliersForm4: 'Region',
        partnershipForSuppliersForm5: 'Contact person',
        partnershipForSuppliersForm6: 'Country Code',
        partnershipForSuppliersForm6Error: 'Choose country code',
        partnershipForSuppliersForm7: 'Phone',
        partnershipForSuppliersForm7Example: 'example: ',
        partnershipForSuppliersForm8: 'Attach files',
        partnershipForSuppliersForm9: 'Comment',
        partnershipForSuppliersForm10: 'Send',
        partnershipForSuppliersForm11: 'Application Content',
        partnershipForSuppliersForm12: 'To form a request',
        partnershipForSuppliersForm13: 'Application is formed!',
        partnershipForSuppliersForm14: 'You didn\'t fill out the form correctly',
        partnershipOurPartnersSubHead1: 'Key Medicine Suppliers',
        partnershipOurPartnersText: 'Over 100 leading pharmaceutical manufacturers',
        partnershipOurPartnersSubHead2: 'Key Non-Medcines Suppliers',
        partnershipOurPartnersSubHead3: 'Key <<<...>> Suppliers',
        careerSubHead: 'Careers in the Company',
        careerSubTitle1: 'What we offer?',
        careerSubTitle2: 'Do you have questions about employment?',
        careerSubTitle3: 'Jobs',
        careerText1: 'At SmolMedPreparaty LLC, each employee is a full-fledged partner. ' +
            'We create a comfortable working environment, a friendly atmosphere, opportunities ' +
            'for growth, and career building, we righteously reward our employees and appreciate those ' +
            'who achieve high results. ',
        careerText2: 'Career and professional growth',
        careerText3: 'Decent salary',
        careerText4: 'Comprehensive training and development',
        careerText5: 'Contact phone: ',
        careerText6: 'You can look up-to-date information on company vacancies on the website',
        careerText7: 'You can also submit your CV via the following form: ',
        careerWorkForm1: 'Full name',
        careerWorkForm2: 'Vacancy',
        careerWorkForm3: 'City',
        careerWorkForm4: 'Attach Files',
        careerWorkForm5: 'Text Message',
        careerWorkForm6: 'Submit your CV',
        careerWorkForm7: 'By clicking the "Send" button, you agree to',
        careerWorkForm8: ' the policy of processing personal data',
        careerWorkForm9: 'Your resume has been sent!',
        careerWorkForm10: 'You filled out your resume incorrectly',
        feedBackForm: 'Feedback',
        feedBackForm1: 'How can I call you?',
        feedBackForm2: 'Indicate Email for feedback',
        feedBackForm3: 'Message text',
        feedBackForm4: 'You didn\'t fill out the form correctly',
        contactSubTitle1: 'Head Office',
        contactSubTitle2: 'Central Warehouse',
        contactText1: 'Russia, Smolensk, Phoenix Industrial Park, 6',
        contactText2: '1 Solnechnaya St, Office No.1, Miknovka Village, Smolensk, Smolensk, Russia',
        contactPhone: 'Phone',
        contactText3: 'We look forward to your feedback and suggestions!',
        contactText4: 'Email us',
        contactText5: 'Or by filling out',
        contactText6: 'the feedback form',
        contactInfo: 'All rights reserved ©, 2019-2020 LLC SmolMedPreparaty. Site creation: ',
        contactITMosaic: ' “IT-Mosaic”',
        effectivnessContentMiddlePrice: 'Average price -',
        effectivnessContentAfterRealization: 'After the implementation of the project - less then ',
        effectivnessContentRub: ' rub',
        projectProductsSubTitle: 'Products in project',
        ourProductsHeading: 'Products',
        ourProductsSubTitle1: 'Our products',
        ourProductsSubTitle2: 'Products in Development'
      }
    }
  }
})

/**
 * @param {String} locale
 */
export async function loadMessages (locale) {
  if (Object.keys(i18n.getLocaleMessage(locale)).length === 0) {
    const messages = await import(/* webpackChunkName: "[request]" */ `@/lang/${locale}`)
    i18n.setLocaleMessage(locale, messages)
  }

  if (i18n.locale !== locale) {
    i18n.locale = locale
  }
}

(async function () {
  await loadMessages(store.getters['lang/locale'])
})()

export default i18n
