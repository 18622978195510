<template>
    <div style="min-width:157px;">
        <v-col cols="6" lg="2" md="4" class="mx-6 px-0" style="max-width:157px;">
                <v-row class="my-3 mx-auto text-center">
                    <v-col cols="12">
                        <img :src=this.itemImage
                        width="60"
                        height="40"/>
                    </v-col>
                </v-row>
                <v-row class="my-3 mx-auto text-center">
                    <v-col cols="12" style="text-align:center;font-size:22px; border-top: 1px solid rgba(30, 171, 214, 1)" class="py-2">
                        <span>{{ number }}</span> <span>{{ text }}</span>
                    </v-col>
                </v-row>
        </v-col>
    </div>
</template>
<script>
export default {
  props: { img_name: String,
    number: String,
    text: String },
  name: 'ContentTextsvg',
  computed: {
    itemImage () {
      const StrImgName = String(this.img_name)
      return require('@/../public/img/effect/' + StrImgName + '.png')
    }
  }
}
</script>
