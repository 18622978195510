import * as types from '../mutation-types'
import axios from 'axios'

// state
export const state = {
  limit: 10,
  offset: 0,
  filters: [],
  news: []
}

// getters
export const getters = {
  limit: state => state.limit,
  offset: state => state.offset,
  filters: state => state.filters,
  news: state => state.news
}

// mutations
export const mutations = {
  [types.SET_NEWS] (state, { news }) { state.news = news },
  [types.SET_LIMIT_AND_OFFSET] (state, { limit, offset, filters }) {
    if (typeof (filters) !== 'undefined') { state.filters = filters }
    state.limit = limit
    state.offset = offset
    this.dispatch('news/GET_NEWS', { 'filters': state.filters, 'limit': state.limit, 'offset': state.offset })
  }

}

// actions
export const actions = {
  'GET_NEWS': async function ({ commit }, { filter, limit, offset }) {
    const { data } = await axios.post(
      '/api/news',
      { 'filters': state.filters, 'limit': limit, 'offset': offset }
    )
    commit(types.SET_NEWS, { news: data['result'] })
  }
}
