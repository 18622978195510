<template>
    <div class="text-center mt-3">
          <v-btn dark
               color="light-blue darken-2"
               x-large
               @click="$vuetify.goTo(target, options)"
               v-show="!hidden"
               style="height:100%"
            >
            <v-col>
                <v-row class="mt-1 mx-3">Показать еще</v-row>
                <v-row><v-icon dark class="mx-auto mb-2">{{ svgDown }}</v-icon>
                </v-row>
            </v-col>
        </v-btn>
    </div>
</template>
<script>
import { mdiChevronDown } from '@mdi/js'
export default {
  name: 'ButtonExpand',
  data () {
    return {
      svgDown: mdiChevronDown,
      hidden: false
    }
  },
  methods: {

  },
  computed: {
    target () {
      return null
    },
    options () {
      return null
    }
  }
}
</script>
