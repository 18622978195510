<template>
   <v-card class="mx-auto borderCardMobile pb-12"
           width="100%"
           height="100%"
           outlined
   >
       <v-row style="padding-top: 48px; padding-bottom: 24px;">
           <v-col>
               <v-list-item two-line class="text-center pl-2 pr-2">
                   <v-list-item-content class="my-auto">
                       <v-list-item-title class="headlineCardSlogan headlineCardSloganMobile mt-4 mt-lg-4 mt-sm-4">
                           {{ $t('message.companyName') }}
                       </v-list-item-title>
                       <v-list-item-subtitle class="subtitleCardSlogan mt-8">
                           {{ $t('message.slogan') }}
                       </v-list-item-subtitle>
                   </v-list-item-content>
               </v-list-item>
           </v-col>
       </v-row>
       <v-row>
           <v-col cols="4" class="text-center">
               <v-icon class="mt-lg-9 mt-md-5">{{ svgNum1 }}</v-icon>
           </v-col>
           <v-col cols="8" class="pt-0 pl-0">
               <v-list-item two-line class="cardItem">
                   <v-list-item-content class="pt-0">
                       <v-list-item-title class="headlineCardChiliad mt-sm-1 mb-sm-6">
                           25 <span class="headlineCardHundred">000 </span>+
                       </v-list-item-title>
                       <v-list-item class="subtitleCard mt-sm-1 mb-sm-6 text-left">{{ $t('message.contentNumeric1') }}</v-list-item>
                </v-list-item-content>
              </v-list-item>
           </v-col>
       </v-row>
       <v-row>
           <v-col cols="4" class="text-center">
               <v-icon class="mt-lg-9 mt-md-5">{{ svgNum2 }}</v-icon>
           </v-col>
           <v-col cols="8" class="pt-0 pl-0">
               <v-list-item two-line class="cardItem">
                   <v-list-item-content class="pt-0">
                       <v-list-item-title class="headlineCardChiliad mt-sm-1 mb-sm-6">
                           3 <span class="headlineCardHundred">000 {{ $t('message.contentUnit2') }} </span>+
                       </v-list-item-title>
                       <v-list-item class="subtitleCard mt-sm-1 mb-sm-6 text-left">{{ $t('message.contentNumeric2') }}</v-list-item>
                </v-list-item-content>
              </v-list-item>
           </v-col>
       </v-row>
       <v-row>
           <v-col cols="4" class="text-center">
               <v-icon class="mt-lg-9 mt-md-5">{{ svgNum3 }}</v-icon>
           </v-col>
           <v-col cols="8" class="pt-0 pl-0">
               <v-list-item two-line class="cardItem">
                   <v-list-item-content class="pt-0">
                       <v-list-item-title class="headlineCardChiliad mt-sm-1 mb-sm-6">
                           1 <span class="headlineCardHundred">{{ $t('message.contentUnit3') }}</span> +</v-list-item-title>
                       <v-list-item class="subtitleCard mt-sm-1 mb-sm-6 text-left">{{ $t('message.contentNumeric3') }}</v-list-item>
                </v-list-item-content>
              </v-list-item>
           </v-col>
       </v-row>
       <v-row>
           <v-col cols="4" class="text-center">
               <v-icon class="mt-lg-9 mt-md-5">{{ svgNum4 }}</v-icon>
           </v-col>
           <v-col cols="8" class="pt-0 pl-0">
               <v-list-item two-line class="cardItem">
                   <v-list-item-content class="pt-0">
                       <v-list-item-title class="headlineCardChiliad mt-sm-1 mb-sm-6">95 +</v-list-item-title>
                       <v-list-item class="subtitleCard mt-sm-1 mb-sm-6 text-left">{{ $t('message.contentNumeric4') }}</v-list-item>
                </v-list-item-content>
              </v-list-item>
           </v-col>
       </v-row>
    </v-card>
</template>
<script>
import '../../plugins/i18n.js'
import { mdiAccountMultipleOutline, mdiBasketOutline, mdiCurrencyRub, mdiStore } from '@mdi/js'
export default {
  data () {
    return {
      locale: 'ru',
      absolute: true,
      overlay: false,
      svgNum1: mdiBasketOutline,
      svgNum2: mdiStore,
      svgNum3: mdiCurrencyRub,
      svgNum4: mdiAccountMultipleOutline,
      text: 'высокотехнологичные, лицензированные склады'
    }
  },
  methods: {
    changeLocaleRu () { this.$i18n.locale = 'ru' },
    changeLocaleEn () { this.$i18n.locale = 'en-US' }
  }
}
</script>
