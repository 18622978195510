<template>
  <v-container fluid>
    <v-row class="mt-16 pl-4 py-4">
      <v-col cols="12" lg="6" md="6">
        <div v-if="product">
                  <v-img class="mx-auto"
          width="100%"
          max-width="650"
                         contain
          :src=GetImage>
      </v-img>
        </div>
          <v-row class="my-5">
              <v-col cols="12" class="mx-auto text-center mt-7 mb-5">
                  <v-btn
                      v-if="product.instruction"
                      outlined
                      x-large
                      color="blue darken-2"
                  ><a download="" :href="product.instruction" style="text-decoration: none">Инструкция к товару</a></v-btn>
              </v-col>
          </v-row>
      </v-col>
      <v-col v-if="product" cols="12" lg="6" md="6" class="px-12 mb-14">
        <h1 v-if="this.lang=='ru'" class="" style="font-size:32px">{{product.mnn}}</h1>
        <h1 v-if="this.lang=='en-US'" class="" style="font-size:32px">{{product.en_mnn}}</h1>
        <div v-for="parametr in listParametr" :key=parametr.lable style="font-size:16px">
        <v-row class="mt-8"  style="border-bottom:1px solid black; word-wrap: break-word">
            <v-col cols=6 >
              <div>{{parametr.lable}}</div>
            </v-col>
            <v-col cols=6>
              <div style="text-align: right">{{parametr.value}}</div>
            </v-col>
        </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'ContentProduct',
  data () {
    this.$store.dispatch('products/GET_PRODUCTS', { 'filters': [], 'limit': 10, 'offset': 0 })
    return {
      toggle_exclusive: 4
    }
  },
  computed: {
    lang () { return this.$i18n.locale },
    product () { return this.$store.state['products']['products'].filter(product => product.mnn === this.mnn)[0] },
    // product () { return this.$store.getters['products/product'](this.mnn) },
    GetImage () {
      if (this.product.img === '') {
        if (this.lang === 'ru') return require('@/../public/img/Errors/no-image.png')
        if (this.lang === 'en-US') return require('@/../public/img/Errors/en_no-image.png')
      } else return this.product.img
    },
    listParametr: function () {
      if (this.lang === 'ru') {
        let params = []
        if (this.product.product_conditions) {
          params.push({ 'lable': 'Условия отпуска', 'value': 'По рецепту' })
        } else {
          params.push({ 'lable': 'Условия отпуска', 'value': 'Без рецепта' })
        }
        if (this.product.dosage !== '') {
          params.push({ 'lable': 'Дозировка', 'value': this.product.dosage })
        }
        if (this.product.farm_group !== '') {
          params.push({ 'lable': 'Фармако-терапевтическая группа', 'value': this.product.farm_group })
        }
        if (this.product.form_of_release !== '') {
          params.push({ 'lable': 'Форма выпуска', 'value': this.product.form_of_release })
        }
        if (this.product.price != null) {
          params.push({ 'lable': 'Стоимость', 'value': this.product.price })
        }
        return params
      }
      if (this.lang === 'en-US') {
        let params = []
        if (this.product.product_conditions) {
          params.push({ 'lable': 'Release conditions', 'value': 'With prescription' })
        } else {
          params.push({ 'lable': 'Release conditions', 'value': 'Without prescription' })
        }
        if (this.product.dosage !== '') {
          params.push({ 'lable': 'Dosage', 'value': this.product.en_dosage })
        }
        if (this.product.farm_group !== '') {
          params.push({ 'lable': 'Pharmaco-therapeutic group', 'value': this.product.en_farm_group })
        }
        if (this.product.form_of_release !== '') {
          params.push({ 'lable': 'Release form', 'value': this.product.en_form_of_release })
        }
        if (this.product.price != null) {
          params.push({ 'lable': 'Price', 'value': this.product.price })
        }
        return params
      }
    }
  },
  props: { mnn: String }
}
</script>
