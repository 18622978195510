<template>
  <div>
    <v-row class="mb-3">
      <v-col cols="2">
        <div class="py-10" style="text-align: center">
          {{ this.name }}
        </div>
      </v-col>
      <v-col cols="10" class="px-0">
        <div class=" pl-8  py-1" style="border-left: 1px solid rgba(30, 171, 214, 1)">
          <p class="mt-4">{{$t('message.effectivnessContentMiddlePrice') }} {{ this.GetOldPrice}}{{$t('message.effectivnessContentRub')}}</p>
          <p>{{$t('message.effectivnessContentAfterRealization') }}{{ this.GetPrice }}{{$t('message.effectivnessContentRub')}}</p>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="3" sm="3" md="3" lg="2" class="  no-indentation" >
        <div class="py-6" style="border: 1px rgba(30, 171, 214, .6) solid; text-align: center; color: white; font-weight: bolder; height: 70px; background-color: rgba(30, 171, 214, .6)">
          {{ this.GetPrice }}{{$t('message.effectivnessContentRub')}}
        </div>
      </v-col>
      <v-col cols="2" sm="2" md="2" lg="2" class="no-indentation pt-9">
        <div>
          <hr style="color:rgba(30, 171, 214, 1)">
        </div>
      </v-col>
      <v-col cols="2" sm="2" md="2" lg="4" class="  no-indentation" style="max-width: 14%;">
        <v-img :src=this.GetImage contain  max-height="80" ></v-img>
      </v-col>
      <v-col cols="2" sm="2" md="2" lg="2" class="no-indentation pt-9">
        <div>
          <hr style="color:rgba(30, 171, 214, 1)">
        </div>
      </v-col>
      <v-col cols="3" sm="3" md="3" lg="2" class="no-indentation">
        <div class="py-6" style="text-align: center;color: white; font-weight: bolder; border: 1px rgba(30, 171, 214, .6) solid;height: 70px;background-color: rgba(30, 171, 214, .6)">
          {{ this.GetOldPrice }}{{$t('message.effectivnessContentRub')}}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ContentEffectdown',
  props: {
    name: String,
    price: Number,
    img: String
  },
  computed: {
    lang () { return this.$i18n.locale },
    GetPrice: function () {
      if (this.price == null) { return '—' } else return this.price.toFixed(2)
    },
    GetOldPrice: function () {
      if (this.price == null) { return '—' } else return (this.price * 1.4).toFixed(2)
    },
    GetImage () {
      if (String(this.img) === '') {
        if (this.lang === 'ru') return require('@/../public/img/Errors/no-image.png')
        if (this.lang === 'en-US') return require('@/../public/img/Errors/en_no-image.png')
      } else { return this.img }
    }
  }

}
</script>

<style scoped>

</style>
