import { render, staticRenderFns } from "./SliderRU.vue?vue&type=template&id=023f292c&scoped=true"
import script from "./SliderRU.vue?vue&type=script&lang=js"
export * from "./SliderRU.vue?vue&type=script&lang=js"
import style0 from "./SliderRU.vue?vue&type=style&index=0&id=023f292c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023f292c",
  null
  
)

export default component.exports