<template>
    <v-toolbar class="menu-toolbar">
        <div class="mobileMenuPlace">
            <v-menu bottom transition="scroll-x-transition">
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" class="mobileMenu">
                        <v-icon color="#0285e3">mdi-menu</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-btn icon>
                        <v-icon color="#0285e3">mdi-close</v-icon>
                    </v-btn>
                </v-list>
                <hr>
                <v-list style="height: 100vh; width: 62vh!important;">
                    <v-btn-toggle v-model="toggle_exclusive" style="flex-direction: column;">
                    <v-list-item class="list">
                        <v-btn text @click="pushAboutCompany()"
                               class="menuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >{{ $t('message.submenu1') }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn text @click="pushManagement()"
                               class="menuBtnMobile undermenuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >
                            <v-icon>{{ svgPath1 }}</v-icon>
                            {{ $t('message.aboutUsSubmenu1') }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn text @click="pushDocuments()"
                               class="menuBtnMobile undermenuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >
                            <v-icon>{{ svgPath2 }}</v-icon>
                            {{ $t('message.aboutUsSubmenu2') }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn text @click="pushTypesOfActivities()"
                               class="menuBtnMobile undermenuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >
                            <v-icon>{{ svgPath3 }}</v-icon>
                            {{ $t('message.aboutUsSubmenu3') }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn text @click="pushAnticorruptionPolicy()"
                               class="menuBtnMobile undermenuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >
                            <v-icon>{{ svgPath4 }}</v-icon>
                            {{ $t('message.aboutUsSubmenu4') }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn text @click="pushAllNews()"
                               class="menuBtnMobile undermenuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >
                            <v-icon>{{ svgPath5 }}</v-icon>
                            {{ $t('message.aboutUsSubmenu5') }}</v-btn>
                    </v-list-item>

                    <v-list-item>
                        <v-btn text @click="pushPartnership()"
                               class="menuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >{{ $t('message.submenu4') }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn text @click="pushEcology()"
                               class="menuBtnMobile undermenuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >
                            <v-icon>{{ svgPath6 }}</v-icon>
                            {{ $t('message.partnershipSubmenu1') }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn text @click="pushOurPartners()"
                               class="menuBtnMobile undermenuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >
                            <v-icon>{{ svgPath8 }}</v-icon>
                            {{ $t('message.partnershipSubmenu3') }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn text @click="pushEffect()"
                               class="menuBtnMobile undermenuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >
                            <v-icon>{{ svgPath9 }}</v-icon>
                            {{ $t('message.partnershipSubmenu4') }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn text @click="pushOurProducts()"
                               class="menuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >{{ $t('message.submenu2') }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn text @click="pushProjectProducts()"
                               class="menuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >{{ $t('message.submenu3') }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn text @click="pushCareer()"
                               class="menuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >{{ $t('message.submenu5') }}</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn text @click="pushContacts()"
                               class="menuBtnMobile"
                               block
                               color="light-blue darken-2"
                        >{{ $t('message.submenu6') }}</v-btn>
                    </v-list-item></v-btn-toggle>
                </v-list>
            </v-menu>
        </div>
        <div class="mobileLogoPlace">
            <a @click="pushMain()"><img src="../../../public/img/logos/logo22.png"/></a>
        </div>
        <div class="mobileFeedBack">
            <v-btn icon @click.stop="dialog = true" class="mobileMenu">
              <v-icon color="#BACF34">mdi-email-edit-outline</v-icon>
            </v-btn>
                  <v-row justify="center">
                      <v-dialog v-model="dialog"
                                max-width="568"
                      >
                          <v-card class="pa-8">
                              <v-card-title class="headline" style="color:#0285e3">Обратная связь</v-card-title>
                              <v-card-text>
                                  <Feedback></Feedback>
                              </v-card-text>
                          </v-card>
                      </v-dialog>
                  </v-row>
        </div>
        <div class="flex-grow-1"></div>
        <v-toolbar-items class="no-indentation">
            <v-btn-toggle v-model="toggle_exclusive"
                          class="menuBtnToggle"
            >
                <v-btn @click="pushAboutCompany()" class="menuBtn">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <v-icon>{{ svgBtnPath1 }}</v-icon>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                {{ $t('message.submenu1') }}
                            </v-col>
                        </v-row>
                    </v-container>
                </v-btn>
                <v-btn @click="pushOurProducts()" class="menuBtn">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <v-icon>{{ svgBtnPath2 }}</v-icon>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pa-1 text-wrap">
                                {{ $t('message.submenu2') }}
                            </v-col>
                        </v-row>
                    </v-container>
                </v-btn>
                <v-btn @click="pushProjectProducts()" class="menuBtn">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <v-icon>{{ svgBtnPath3 }}</v-icon>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pa-1 text-wrap">
                                {{ $t('message.submenu3') }}
                            </v-col>
                        </v-row>
                    </v-container>
                </v-btn>
                <v-btn @click="pushPartnership()" class="menuBtn">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <v-icon>{{ svgBtnPath4 }}</v-icon>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                {{ $t('message.submenu4') }}
                            </v-col>
                        </v-row>
                    </v-container>
                </v-btn>
                <v-btn @click="pushCareer()" class="menuBtn">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <v-icon>{{ svgBtnPath5 }}</v-icon>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pa-3">
                                {{ $t('message.submenu5') }}
                            </v-col>
                        </v-row>
                    </v-container>
                </v-btn>
                <v-btn @click="pushContacts()" class="menuBtn">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <v-icon>{{ svgBtnPath6 }}</v-icon>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pa-3">
                                {{ $t('message.submenu6') }}
                            </v-col>
                        </v-row>
                    </v-container>
                </v-btn>
            </v-btn-toggle>
        </v-toolbar-items>
    </v-toolbar>
</template>
<script>
import '../../plugins/i18n.js'
import Feedback from '../ui/FeedbackForm'
import {
  mdiHomeOutline,
  mdiHexagonMultipleOutline,
  mdiHumanMaleHeight,
  mdiPhoneLogOutline,
  mdiAccountCheckOutline,
  mdiFileDocumentBoxCheckOutline,
  mdiEqualizerOutline,
  mdiShieldCheckOutline,
  mdiBullhornOutline,
  mdiTruckFast,
  mdiCarInfo,
  mdiAccountTieOutline,
  mdiAccountHeartOutline,
  mdiPill,
  mdiMedicalBag
} from '@mdi/js'
export default {
  components: { Feedback },
  data () {
    return {
      dialog: false,
      toggle_exclusive: undefined,
      locale: 'ru',
      absolute: true,
      overlay: false,
      svgPath1: mdiAccountCheckOutline,
      svgPath2: mdiFileDocumentBoxCheckOutline,
      svgPath3: mdiEqualizerOutline,
      svgPath4: mdiShieldCheckOutline,
      svgPath5: mdiBullhornOutline,
      svgPath6: mdiTruckFast,
      svgPath7: mdiCarInfo,
      svgPath8: mdiAccountTieOutline,
      svgPath9: mdiAccountHeartOutline,
      svgBtnPath1: mdiHomeOutline,
      svgBtnPath2: mdiMedicalBag,
      svgBtnPath3: mdiPill,
      svgBtnPath4: mdiHexagonMultipleOutline,
      svgBtnPath5: mdiHumanMaleHeight,
      svgBtnPath6: mdiPhoneLogOutline
    }
  },
  methods: {
    on () { console.log('write me on from toolbar') },
    pushMain () {
      this.$router.push('/')
    },
    pushAboutCompany () {
      this.$router.push('/about_company')
    },
    pushManagement () {
      this.$router.push('/management')
    },
    pushDocuments () {
      this.$router.push('/documents')
    },
    pushTypesOfActivities () {
      this.$router.push('/types_of_activities')
    },
    pushAnticorruptionPolicy () {
      this.$router.push('/anticorruption_policy')
    },
    pushAllNews () {
      this.$router.push('/news')
    },
    pushPartnership () {
      this.$router.push('/partnership')
    },
    pushEcology () {
      this.$router.push('/ecology_security')
    },
    pushForSuppliers () {
      this.$router.push('/for_suppliers')
    },
    pushOurPartners () {
      this.$router.push('/our_partners')
    },
    pushEffect () {
      this.$router.push('/effect')
    },
    pushCareer () {
      this.$router.push('/career')
    },
    pushContacts () {
      this.$router.push('/contacts')
    },
    pushOurProducts () {
      this.$router.push('/our_products')
    },
    pushProjectProducts () {
      this.$router.push('/project_products')
    }
  }
}
</script>
