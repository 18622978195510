<template>
   <v-card class="mx-auto newsBorderCard text-center"
           width="100%"
           height="100%"
           outlined
   >
       <v-list-item>
           <v-list-item-icon style="margin-bottom: 0!important;">
               <v-icon>{{ svgPath }}</v-icon>
           </v-list-item-icon>
           <div class="flex-grow-1"></div>
           <v-list-item-content class="mr-lg-6 mr-md-2 mr-sm-2 dataNews">
               <span class="text-right">{{ data}}</span>
           </v-list-item-content>
       </v-list-item>
       <v-list-item three-line>
           <v-list-item-content style="padding: 0!important;">
               <v-list-item class="mb-4 text-left ml-lg-2 ml-md-0 mr-lg-2 mr-md-2 newsHeading">
                   <a @click="pushAllNews(anchor)">{{ header }}</a>
               </v-list-item>
               <v-list-item-subtitle class="text-left ml-lg-6 mr-lg-10 ml-md-2 mr-md-2 ml-sm-2 mr-sm-2 newsText">
                   <span>{{ text }}</span>
               </v-list-item-subtitle>
           </v-list-item-content>
       </v-list-item>
       <v-card-actions>
           <div class="flex-grow-1"></div>
            <v-btn @click="pushAllNews(anchor)" icon right width="48" height="48" style="background-color: #FDFDFD!important;" class="mr-6 mt-lg-5 mt-md-3 mt-sm-2 mb-lg-2 mb-md-2 mb-sm-3">
                <v-icon>{{ svgBtnPath }}</v-icon>
            </v-btn>
       </v-card-actions>
   </v-card>
</template>
<script>
import '../../plugins/i18n.js'
import { mdiCloudOutline, mdiArrowRight } from '@mdi/js'
export default {
  props: ['header', 'text', 'data', 'anchor'],
  data () {
    return {
      locale: 'ru',
      absolute: true,
      overlay: false,
      svgPath: mdiCloudOutline,
      svgBtnPath: mdiArrowRight
    }
  },
  methods: {
    changeLocaleRu () { this.$i18n.locale = 'ru' },
    changeLocaleEn () { this.$i18n.locale = 'en-US' },
    pushAllNews (value) {
      this.$router.push('/news_page#' + value)
    }
  }
}
</script>
