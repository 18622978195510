<template>
   <v-card class="mx-auto borderCard"
           width="100%"
           height="100%"
           outlined
   >
       <v-list-item two-line class="pt-lg-12 pt-md-11 pt-sm-12 pt-4 text-center">
           <v-list-item-content>
          <v-list-item-title class="headlineCardSlogan mt-4 mt-lg-4 mt-sm-4">{{ $t('message.companyName') }}</v-list-item-title>
          <v-list-item-subtitle class="subtitleCardSlogan mt-4 mb-4 mt-lg-8 mb-lg-12 mt-sm-8 mb-sm-6">"{{ $t('message.slogan') }}"</v-list-item-subtitle>
        </v-list-item-content>
       </v-list-item>
    </v-card>
</template>
<script>
import '../../plugins/i18n.js'
export default {
  data () {
    return {
      locale: 'ru',
      absolute: true,
      overlay: false
    }
  },
  methods: {
    changeLocaleRu () { this.$i18n.locale = 'ru' },
    changeLocaleEn () { this.$i18n.locale = 'en-US' }
  }
}
</script>
