<template>
    <div>
        <div class="contentNumeric">
            {{ num }} {{ unit }} {{ symbol }}
        </div>
        <div class="contentText ml-0 mt-2">
            {{ content}}
        </div>
    </div>
</template>
<script>
export default {
  props: ['subtitle', 'num', 'unit', 'symbol', 'content'],
  name: 'ContentNumeric'
}
</script>
