<template>
    <div style="width: 100%!important;" class="mx-5">
        <v-btn-toggle v-model="toggle_none"
                      class="menuBtnToggle">
            <div class="subMenuBlock">
                <Submenu :submenu="$t('message.aboutUsSubmenu1')" :push="pushManagement" :svg-path="svgPath1"></Submenu>
            </div>
            <div class="subMenuBlock">
                <Submenu :submenu="$t('message.aboutUsSubmenu2')" :push="pushDocuments" :svg-path="svgPath2"></Submenu>
            </div>
            <div class="subMenuBlock">
                <Submenu :submenu="$t('message.aboutUsSubmenu3')" :push="pushTypesOfActivities" :svg-path="svgPath3"></Submenu>
            </div>
            <div class="subMenuBlock">
                <Submenu :submenu="$t('message.aboutUsSubmenu4')" :push="pushAnticorruptionPolicy" :svg-path="svgPath4"></Submenu>
            </div>
            <div class="subMenuBlock">
                <Submenu :submenu="$t('message.aboutUsSubmenu5')" :push="pushAllNews" :svg-path="svgPath5"></Submenu>
            </div>
        </v-btn-toggle>
    </div>
</template>
<script>
import Submenu from '../ui/Submenu'
import { mdiAccountCheckOutline, mdiFileDocumentBoxCheckOutline, mdiEqualizerOutline, mdiShieldCheckOutline, mdiBullhornOutline } from '@mdi/js'
export default {
  components: { Submenu },
  props: ['toggle_none'],
  name: 'AboutCompanySubmenu',
  data () {
    return {
      svgPath1: mdiAccountCheckOutline,
      svgPath2: mdiFileDocumentBoxCheckOutline,
      svgPath3: mdiEqualizerOutline,
      svgPath4: mdiShieldCheckOutline,
      svgPath5: mdiBullhornOutline
    }
  },
  methods: {
    pushManagement () {
      this.$router.push('/management')
    },
    pushDocuments () {
      this.$router.push('/documents')
    },
    pushTypesOfActivities () {
      this.$router.push('/types_of_activities')
    },
    pushAnticorruptionPolicy () {
      this.$router.push('/anticorruption_policy')
    },
    pushAllNews () {
      this.$router.push('/news')
    }
  }
}
</script>
